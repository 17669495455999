<template>
  <div class="page-reports">
    <b-tabs card>
      <b-tab title="Users">
        <Users />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Users from '@/components/Reports/Users.vue'

export default {
  name: 'Reports',
  components: {
    Users,
  },
}
</script>