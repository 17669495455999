<template>
  <div>

    <b-navbar toggleable="lg" class="mb-3">
      <b-navbar-nav class="ml-auto mt-4 with-label-prepended" data-label="DATE RANGE">
        <b-form>
          <div style="float: left;">
            <b-form-datepicker
              v-model="dateFrom"
              :max="dateTo"
              right
              @input="onChangedDateFrom"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="Start Date"
              :disabled="loading"
              value-as-date
            ></b-form-datepicker>
          </div>
          <span class="mx-2">-</span>
          <div style="float: right;">
            <b-form-datepicker
              v-model="dateTo"
              :min="dateFrom"
              right
              @input="onChangedDateTo"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="End Date"
              :disabled="loading"
              value-as-date
            ></b-form-datepicker>
          </div>
        </b-form>
      </b-navbar-nav>
    </b-navbar>

    <b-overlay :show="loading">

      <b-table
        hover
        :items="users"
        :fields="fields"
        small
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(last_accessed_at)="row">
          {{ row.item.last_accessed_at && moment(row.item.last_accessed_at).format('MMM DD, Y h:mm A') || '' }}
        </template>
      </b-table>

    </b-overlay>

  </div>
</template>

<script>
export default {
  name: 'Users',
  data() {
    const sDate = new Date()
    sDate.setDate(sDate.getDate() - 6)
    return {
      dateFrom: sDate,
      dateTo: new Date(),
      allSelected: false,
      indeterminate: false,
      fields: [
        {
          key: 'name',
          class: 'text-nowrap',
          thClass: 'text-nowrap small',
          sortable: true,
        },
        {
          key: 'views',
          class: 'text-nowrap',
          thClass: 'text-nowrap small',
          sortable: true,
        },
        {
          key: 'last_accessed_at',
          label: 'Last Access',
          thClass: 'text-nowrap small',
          sortable: true,
        },
        {
          key: 'alerts',
          label: '# Alerts/Filters',
          thClass: 'text-nowrap small',
          sortable: true,
        },
        {
          key: 'contact_clicks',
          label: 'Contact Clicks(API hits)',
          thClass: 'text-nowrap small',
          sortable: true,
        },
      ],
      sortBy: 'last_accessed_at',
      sortDesc: true,
      usersData: [],
      loading: false,
    }
  },
  computed: {
    users: function() {
      return this.usersData.map(user => ({
        ...user,
        _rowVariant: !user.is_active ? 'secondary' : undefined,
        alerts: user.alerts || 0,
        views: user.views || 0,
        contact_clicks: user.contact_clicks || 0,
      }))
    },
  },
  watch: {
  },
  methods: {
    onChangedDateFrom(dateFrom) {
      this.$store.commit('filters/setDateFrom', dateFrom)
      this.onFilterChanged()
    },
    onChangedDateTo(dateTo) {
      this.$store.commit('filters/setDateTo', dateTo)
      this.onFilterChanged()
    },
    onFilterChanged() {
      this.loading = true
      const payload = {
        dateFrom: new Date(this.dateFrom),
        dateTo: new Date(this.dateTo),
      }
      payload.dateFrom.setHours(0, 0, 0, 0)
      payload.dateTo.setHours(24, 0, 0, 0)
      this.$store.dispatch('reports/loadUsers', payload)
      .then((data) => {
        this.usersData = data
      })
      .finally(() => this.loading = false)
    },
  },
  mounted() {
    this.onFilterChanged()
  },
}
</script>